<!--
 * @Descripttion: 供应商列表
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-19 17:49:23
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-02-26 15:11:42
-->
<template>
    <div class="supplierList">
        <div class="sl-title">
            <div class="st-left">
                <div class="st-search">
                    <div class="c-search">
                        <div><i class="el-icon-search"></i></div>
                        <el-input v-model="keyword" placeholder="搜索供应商名称或主体账号"></el-input>
                    </div>
                    <div class="c-btn" @click="clickSearch">搜索</div>
                </div>
                <div class="st-date" style="margin-right: 20px">
                    <div class="title">创建时间</div>
                    <div class="content">
                        <commonSelect
                            @changeDate="changeDate"
                            @clickTagDate="clickTagDate"
                        ></commonSelect>
                    </div>
                </div>
                <div class="st-date">
                    <div class="title">状态筛选</div>
                    <el-select @change="statusSelect" v-model="statusValue" placeholder="请选择">
                        <el-option
                        v-for="item in statusOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="st-right">
                <div class="sr-add">
                    <div class="add" @click="clickAddBtn">创建供应商</div>
                </div>
            </div>
        </div>
        <div class="sm-content">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="name" label="供应商名称"></el-table-column>
                <el-table-column prop="phone" label="供应商账号"></el-table-column>
                <el-table-column prop="time" label="供应商创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="business_manager" label="业务经理"></el-table-column>
                <el-table-column  label="合作价格" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div class="table-price" v-if="scope.row.cooperation_price.length>0">
                            <div class="price" v-for="(price,index) in scope.row.cooperation_price" :key="index">
                                <div>{{price.name}}: {{price.price}}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="cooperation_end_time" label="合作价格结束时间" show-overflow-tooltip></el-table-column>
                <el-table-column  width="250">
                    <template slot-scope="scope">
                        <div class="btn-wrap">
                            <div class="btn" @click="clickEdit(scope.row)">编辑信息</div>
                            <div style="color: #ff0000" class="btn" @click="clickDelRow(scope.row)">删除</div>
                            <div class="btn" @click="clickDel(scope.row)">
                                <div style="color: #26c250" v-if="scope.row.status == 1">账号禁用</div>
                                <div style="color: #999" v-else>启用账号</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 添加 -->
        <el-dialog
            :visible.sync="isShow"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="640px">
            <div class="class-wrap">
                <div class="cw-title">{{isShowMD?'编辑供应商':'创建供应商'}}</div>
                <div class="cw-list">
                    <div class="item">
                        <div class="tit">名称</div>
                        <el-input v-model="info.name" placeholder="请输入供应商名称"></el-input>
                    </div>
                    <div class="item">
                        <div class="tit">账号</div>
                        <el-input v-model="info.phone" placeholder="请输入供应商主体账号手机号"></el-input>
                    </div>
                    <div class="item">
                        <div class="tit">业务经理</div>
                        <el-input v-model="info.business_manager" placeholder="请输入业务经理姓名"></el-input>
                    </div>
                    <div class="tip-wrap">
                        <div class="line"></div>
                        <div class="tip">价格政策</div>
                        <div class="line"></div>
                    </div>
                    <div class="item">
                        <div class="item-price">
                            <div class="ip-list" v-for="(item,index) in activatePackage" :key="index">
                                <div class="tit">{{item.name}}</div>
                                <div class="name">原价：{{item.team_price}}</div>
                                <el-input v-model="item.price" placeholder="请输入合作价"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="tit">结束时间</div>
                        <el-date-picker
                            v-model="info.cooperation_end_time"
                            type="datetime"
                            placeholder="请选择合作价格结束时间">
                        </el-date-picker>
                    </div>
                </div>
                <div class="cw-btn" @click="clickAddMain">确定</div>
            </div>
        </el-dialog>
        <!-- 提示 -->
        <el-dialog
            :visible.sync="isDel"
            width="500px"
            :show-close="false">
            <div class="channel-wrap">
                <div class="cw-title">{{delName}}</div>
                <div class="cw-btn">
                    <div class="btn cancel" @click="isDel = false">取消</div>
                    <div class="btn confirm" @click="clickConfirm">确定</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from "@/utils/common.js"
import pageMixin from '@/internal/pageMixin'
import commonSelect from "@/components/commonSelect.vue"
import {getStartEndByPeriod} from "@/utils/timeCycle.js"
export default {
    components:{
        commonSelect
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'supplierList',
            isShow: false,
            isShowMD: false,
            keyword: '',
            info: {
                name: '',
                phone: '',
                business_manager: '',
                cooperation_end_time: '',
            },
            editId: '',
            start_date: '',
            end_date: '',
            tableData: [],
            isDel: false,
            delName: '',
            statusOptions: [{
                id: '',
                name: '全部'
            },{
                id: 1,
                name: '正常'
            },{
                id: 0,
                name: '禁用'
            }],
            statusValue: '',
            activatePackage: []
        }
    },
    mounted () {
        this.getSupplierList();
        this.getActivateWhole();
    },
    methods: {
        // 获取商品套餐价格
        getActivateWhole(){
            api.get('/service/supplier/get_activate_package_whole', {}, (res) =>  {
                let resultSecond =  res.data.list.map(v =>{
                return{
                    id: v.id,
                    name: v.name,
                    price: '',
                    team_price: v.price,
                }
                })
                this.activatePackage = resultSecond
            });
        },
        // 状态筛选
        statusSelect(){
            this.getSupplierList()
        },
        // 确定修改
        clickConfirm(){
            let params = {
                id: this.editId,
            }
            api.post('/service/supplier/supplier_status', params, (res) =>  {
                this.$message.success('状态已修改')
                this.isDel = false
                this.getSupplierList()
            });
        },
        // 删除
        clickDelRow(row){
            this.$confirm('是否确定删除该供应商账号?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params = {
                    id: row.id
                }
                api.post('/service/supplier/supplier_del', params, (res) =>  {
                    this.getSupplierList()
                    this.$message.success(res.msg)
                });
            });
        },
        // 修改状态
        clickDel(row){
            if(row.status == 1){
                this.delName = '确定禁用【'+row.name+'】MCDA平台账号？禁用后平台账号将无法正常登录'
            }else{
                this.delName = '确定启用【'+row.name+'】MCDA平台账号？'
            }
            this.isDel = true;
            this.editId = row.id
        },
        // 获取供应商列表
        getSupplierList(){
            let params = {
                page_num: 1,
                page_size: 100,
                info: this.keyword,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusValue
            }
            api.get('/service/supplier/get_supplier_list', params, (res) =>  {
                this.tableData = res.data.list
            });
        },
        // 编辑
        clickEdit(row){
            this.editId= row.id;
            this.isShowMD = true;
            this.isShow = true;
            this.info.name = row.name;
            this.info.phone = row.phone;
            this.info.business_manager = row.business_manager;
            this.info.cooperation_end_time = row.cooperation_end_time;
           
            for (let i = 0; i < this.activatePackage.length; i++) {
                for (let j = 0; j < row.cooperation_price.length; j++) {
                    if (this.activatePackage[i].id == row.cooperation_price[j].activate_package_id && this.activatePackage[i].name == row.cooperation_price[j].name) {
                        this.activatePackage[i].price = row.cooperation_price[j].price;
                        break;
                    }
                }
            }
        },
        // 添加
        clickAddMain(){
            if(this.info.name == ''){
                this.$message.error('请输入供应商名称')
                return
            }
            if(this.info.phone == ''){
                this.$message.error('请输入供应商主体账号手机号')
                return
            }
            const cooperation_price = this.activatePackage.map(item => {
                return {
                    activate_package_id: item.id,
                    price: item.price
                }
            });
            if(this.isShowMD){
                // 编辑
                let params = {
                    id: this.editId,
                    name: this.info.name,
                    phone: this.info.phone,
                    business_manager: this.info.business_manager,
                    cooperation_price: cooperation_price,
                    cooperation_end_time: this.info.cooperation_end_time,
                }
                api.post('/service/supplier/supplier_edit', params, (res) =>  {
                    this.$message.success('已修改')
                    this.isShow = false
                    this.getSupplierList()
                });
            }else{
                // 添加
                let params = {
                    name: this.info.name,
                    phone: this.info.phone,
                    business_manager: this.info.business_manager,
                    cooperation_price: cooperation_price,
                    cooperation_end_time: this.info.cooperation_end_time,
                }
                api.post('/service/supplier/supplier_add', params, (res) =>  {
                    this.$message.success('已添加')
                    this.isShow = false
                    this.getSupplierList()
                });
            }
        },
        // 搜索
        clickSearch(){
            this.getSupplierList()
        },
        // 创建供应商
        clickAddBtn(){
            this.isShow = true
            this.isShowMD = false
            let info = {
                name: '',
                phone: '',
                business_manager: '',
                cooperation_end_time: '',
            }
            this.info = info
        },
        // 日期选择
        changeDate(startDate,endDate){
            console.log(startDate,endDate);
            this.start_date = startDate.slice(0,11)+' 00:00:01';
            this.end_date = endDate.slice(0,11)+' 23:59:59';
            this.getSupplierList();
        },
        clickTagDate(item){
            console.log(item);
            if(item.label != 'date'){
                let date = getStartEndByPeriod(item.name)
                this.start_date = date.startDate;
                this.end_date = date.endDate;
                this.getSupplierList();
            }
        },
    },
}
</script>

<style lang='scss'>
.supplierList{
    .sl-title{
        background: #fff;
        display: flex;
        justify-content: space-between;
        padding: 10px 40px;
        .st-left{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .st-date{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 10px 0;
                .title{
                    font-size: 16px;
                    color: #101010;
                    padding-right: 30px;
                }
                .el-select{
                    width: 100px;
                }
            }
        }
        .st-right{
            padding: 10px 0;
            .sr-add{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .add{
                    width: 130px;
                    height: 36px;
                    border-radius: 6px;
                    border: 1px solid #0068ff;
                    font-size: 14px;
                    color: #0068ff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
            }
        }
        .st-search{
            display: flex;
            flex-wrap: wrap;
            margin-right: 30px;
            padding: 10px 0;
            .c-search{
                width: 300px;
                height: 40px;
                border-radius: 6px;
                border: 1px solid #ddd;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 0 10px;
                .el-input{
                    width: calc(100% - 20px);
                    .el-input__inner{
                        border: none;
                        outline: none;
                        padding: 10px ;
                    }
                }
            }
            .c-btn{
                width: 100px;
                height: 40px;
                border-radius: 6px;
                background: #0068ff;
                border: 1px solid #0068ff;
                color: #fff;
                font-size: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 20px;
                cursor: pointer;
            }
        }
    }
    .sm-content{
        margin: 20px;
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        .code{
            text-align: center;
            width: 150px;
            padding: 5px 20px;
            border: 1px solid #ddd;
            background: #e6effd;
            border-radius: 9px;
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn{
                padding: 0 15px;
                cursor: pointer;
                font-size: 14px;
                color: #0068ff;
            }
        }
        .table-price{
            display: flex;
            flex-direction: column;
            .price{
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .class-wrap{
        .cw-title{
            font-size: 16px;
            color: #101010;
            padding-left: 20px;
        }
        .cw-list{
            padding: 20px;
            .item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
                .tit{
                    text-align: right;
                    font-size: 15px;
                    color: #101010;
                    width: 100px;
                    padding-right: 20px;
                }
                .el-select{
                    width: 400px;
                }
                .el-date-editor.el-input{
                    width: 465px;
                }
                .item-price{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .ip-list{
                        width: 115px;
                        border: 1px solid #ddd;
                        border-radius: 8px;
                        padding: 10px;
                        .tit{
                            font-size: 15px;
                            color: #101010;
                            font-weight: bold;
                            text-align: center;
                        }
                        .name{
                            font-size: 14px;
                            color: #ff0000;
                            padding: 6px 0;
                            text-align: center;
                        }
                    }
                }
            }
            .tip-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 30px 0;
                .line{
                    width: 150px;
                    height: 1px;
                    background: #ddd;
                }
                .tip{
                    font-size: 15px;
                    color: #101010;
                    padding: 0 30px;
                }
            }
        }
        .cw-btn{
            width: 100px;
            height: 40px;
            border-radius: 4px;
            font-size: 15px;
            color: #fff;
            background: #0068ff;
            margin: 0 auto;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    .channel-wrap{
        padding: 0 80px;
        .cw-title{
            font-size: 15px;
            color: #101010;
            text-align: center;
            padding: 15px 0;
        }
        .cw-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn{
                width: 100px;
                height: 35px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 30px 10px;
                border: 1px solid #0068ff;
                font-size: 15;
                color: #0068ff;
                cursor: pointer;
            }
            .confirm{
                background: #0068ff;
                color: #fff;
            }
        }
    }
}
</style>
